import { PiCertificate } from "react-icons/pi";
import { FaBriefcase } from "react-icons/fa";
import { LuBellRing } from "react-icons/lu";
import { FaCaretDown } from "react-icons/fa";
import { FaPlus } from "react-icons/fa";
import { TiCogOutline } from "react-icons/ti";
import { FaChevronRight } from "react-icons/fa";
import { FaArrowLeft } from "react-icons/fa";
import { AiOutlineThunderbolt } from "react-icons/ai";
import { FaUsers } from "react-icons/fa";
import { MdOutlineSchool } from "react-icons/md";
import { PiBuildingOfficeFill } from "react-icons/pi";
import { BsCast } from "react-icons/bs";
import { VscSignOut } from "react-icons/vsc";
import { PiSignOutBold } from "react-icons/pi";
import { VscSignIn } from "react-icons/vsc";
import Swal from 'sweetalert2';

import React, { useState, useEffect, useRef } from 'react';
import { CSSTransition } from 'react-transition-group';
import { Link, usePage } from "@inertiajs/react";
import { Html_Fuc_Ca } from "@/extension/Extension";
import CreateNewService from "@/components/CreateNewService";
import { Button } from "@headlessui/react";

function Nav() {
	const [showPopup, setShowPopup] = useState(false);
	const togglePopup = () => setShowPopup(!showPopup);
	const createNewService = () => {
		setShowPopup(true);
	};
	return (
		<>
			<Navbar>
				<Logo /> {/* Logo on the left */}
				<NavItemCenter /> {/* Centered NavItem */}
				<NavItemRight createNewService={createNewService} /> {/* Nav items on the right */}
			</Navbar>
			<CreateNewService show={showPopup} onClose={togglePopup} />
		</>
	);
}

function Navbar(props) {
	return (
		<nav className="g s r vd ya cj hh sm _k dj bl ll">
			<div className="bb ze ki xn 2xl:ud-px-0 oo wf yf i">
				<ul className="flex items-center justify-between w-full">{props.children}</ul>
			</div>
		</nav>
	);
}

function NavItem({icon, onClick, children }) {
	const [open, setOpen] = useState(false);

	return (
		<li className="nav-item">
			<a
			   href="#"
				className="icon-button"
				onClick={(e) => {
					if (onClick) {
						onClick(e);
					} else {
						setOpen(!open);
					}
				}}
			>
				{icon}
			</a>
			{open && children}
		</li>
	);
}

// Logo Component (positioned left)
function Logo() {
	return (
		<li className="text-blue-500 font-bold text-xl">
			<Link href={route('app_web_frontend_sitetop')}>
				<img className="w-20" src="/images/logo.png" alt="Logo" />
			</Link>
		</li>
	);
}

// NavItemCenter Component (positioned center like Facebook)
function NavItemCenter() {
	return (
		<li className="flex-grow flex justify-center items-center space-x-6 hidden lg:flex">
			<Link href={route('app_web_frontend_user_search', { id: 'normal' })}>
				<a className="flex items-center space-x-2 px-4 py-2 bg-[#197bb7] text-white rounded-full hover:bg-[#479cd0] transition duration-300 ease-in-out">
					<FaUsers />
					<span>Participant</span>
				</a>
			</Link>
			<Link href={route('app_web_frontend_user_search', { id: 'partner' })}>
				<a className="flex items-center space-x-2 px-4 py-2 bg-[#197bb7] text-white rounded-full hover:bg-[#479cd0] transition duration-300 ease-in-out">
					<MdOutlineSchool />
					<span>Organization</span>
				</a>
			</Link>
			<Link href={route('app_web_frontend_user_search', { id: 'custom001' })}>
				<a className="flex items-center space-x-2 px-4 py-2 bg-[#197bb7] text-white rounded-full hover:bg-[#479cd0] transition duration-300 ease-in-out">
					<PiBuildingOfficeFill />
					<span>Company</span>
				</a>
			</Link>
			<Link href={route('app_web_frontend_case_search', { id: 'type01' })}>
				<a className="flex items-center space-x-2 px-4 py-2 bg-[#197bb7] text-white rounded-full hover:bg-[#479cd0] transition duration-300 ease-in-out">
					<BsCast />
					<span>Jobs</span>
				</a>
			</Link>
		</li>
	);
}

// NavItemRight Component (positioned right)
function NavItemRight({ createNewService }) {
	const user = usePage().props.auth.user;
	return (
		<li className="flex items-center space-x-4">
			{user ? (
				<>
					{user?.authoritys == 'ROLE_AUTHORITY_NORMAL' || user?.authoritys == 'ROLE_AUTHORITY_ADMIN' && (
						<NavItem icon={<FaPlus />} onClick={createNewService} />
					)}
					<NavItem icon={<LuBellRing />} />
					{user?.authoritys == 'ROLE_AUTHORITY_PARTNER' && (
						<NavItem onClick={(e) => window.location.href = route('app_web_frontend_certificate_list')} icon={<PiCertificate />} />
					)}
					{user?.authoritys == 'ROLE_AUTHORITY_CUSTOM001' && (
						<NavItem onClick={(e) => window.location.href = route('app_web_frontend_casemange_list')} icon={<FaBriefcase />} />
					)}
					<NavItem icon={<FaCaretDown />}>
						<DropdownMenu />
					</NavItem>
				</>
			) : (
				<>
					<Link href={route('login')}>
						<a className="flex items-center space-x-2 px-4 py-2 bg-[#197bb7] text-white rounded-full hover:bg-[#479cd0] transition duration-300 ease-in-out">
							<PiSignOutBold />
							<span>Sign In</span>
						</a>
					</Link>
					<Link href={route('app_web_frontend_register_new')}>
						<a className="flex items-center space-x-2 px-4 py-2 bg-[#197bb7] text-white rounded-full hover:bg-[#479cd0] transition duration-300 ease-in-out">
							<PiSignOutBold />
							<span>Sign Up</span>
						</a>
					</Link>
				</>
			)}
		</li>
	);
}

function DropdownMenu() {
	const [isPopupVisible, setPopupVisible] = useState(false);
	const [profiles, setProfiles] = useState([]);
	const [activeMenu, setActiveMenu] = useState('main');
	const [menuHeight, setMenuHeight] = useState(null);
	const dropdownRef = useRef(null);
	const profile = usePage().props.profiles;
	const user = usePage().props.auth.user;

	useEffect(() => {
		setProfiles(profile)
		setTimeout(() => {
			setMenuHeight(dropdownRef.current?.firstChild.offsetHeight + 35)
		}, 500);
	}, [profile])

	function calcHeight(el) {
		const height = el.offsetHeight;
		setMenuHeight(height + 35);
	}

	function DropdownItem(props) {
		return (
			<a href="#" className="menu-item" onClick={() => props.goToMenu && setActiveMenu(props.goToMenu)}>
				<span className="icon-button">{props.leftIcon}</span>
				{props.children}
				<span className="icon-right">{props.rightIcon}</span>
			</a>
		);
	}
	const switchAccount = (accountId) => {
		Swal.fire({
			title: 'Are you sure?',
			text: 'Do you want to switch to this account?',
			icon: 'question',
			showCancelButton: true,
			confirmButtonText: 'Yes, switch!',
			cancelButtonText: 'No, keep current',
		  }).then((result) => {
			if (result.isConfirmed) {
			 	 window.location.href = route('app_web_frontend_switchaccount', { id: accountId });
			}
		  });
	  };
	return (
		<div className="dropdown" style={{ height: menuHeight }} ref={dropdownRef}>
			<CSSTransition
				in={activeMenu === 'main'}
				timeout={500}
				classNames="menu-primary"
				unmountOnExit
				onEnter={calcHeight}>
				<div className="menu">
					<>
					<DropdownItem className="border-b border-gray-300">
						<Link href={route('app_web_frontend_mypage_showprofile')}>
							<span>{user?.name}</span>
						</Link>
					</DropdownItem>
					<hr className="border-t-1 border-gray-300 my-1" />
					{profiles?.owner && (
						profiles.owner.map((owner, index) => (
							<>
								{owner?.account.id !== user.id && (
									<React.Fragment key={index}>
										<DropdownItem leftIcon="👨🏻‍💻">
											<Link 
												onClick={() => switchAccount(owner?.account_id)}
											>
												<span>{owner?.account?.name}</span>
											</Link>
										</DropdownItem>
									</React.Fragment>
								)}
							</>
						))
					)}
					{profiles?.organization && (
						profiles.organization.map((organization, index) => (
							<>
								{organization?.account.id !== user.id && (
									<React.Fragment key={index}>
										<DropdownItem leftIcon="🏡">
											<Link 
												onClick={() => switchAccount(organization?.account_id)}
											>
												<span>{organization?.account?.name}</span>
											</Link>
										</DropdownItem>
									</React.Fragment>
								)}
							</>
						))
					)}
					{profiles?.companies && (
						profiles.companies.map((companies, index) => (
							<>
								{companies?.account.id !== user.id && (
									<React.Fragment key={index}>
										<DropdownItem leftIcon="💼">
											<Link 
												onClick={() => switchAccount(companies?.account_id)}
											>
												<span>{companies?.account?.name}</span>
											</Link>
										</DropdownItem>
									</React.Fragment>
								)}
							</>
						))
					)}
					
					<DropdownItem
						leftIcon={<TiCogOutline />}
						rightIcon={<FaChevronRight />}
						goToMenu="settings">
						Settings
					</DropdownItem>
						<DropdownItem
							leftIcon="💰"
							rightIcon={<FaChevronRight />}
							goToMenu="assets">
							Assets
						</DropdownItem>
						<DropdownItem
							leftIcon={<VscSignOut />}
						>
							<Link href={route('logout')} method="post">
								<span>Logout</span>
							</Link>
						</DropdownItem>
					</>
				</div>
			</CSSTransition>
			<CSSTransition
				in={activeMenu === 'settings'}
				timeout={500}
				classNames="menu-secondary"
				unmountOnExit
				onEnter={calcHeight}>
				<div className="menu">
					<DropdownItem goToMenu="main" leftIcon={<FaArrowLeft />}>
						<h2>My Tutorial</h2>
					</DropdownItem>
					<DropdownItem leftIcon={<AiOutlineThunderbolt />}>HTML</DropdownItem>
					<DropdownItem leftIcon={<AiOutlineThunderbolt />}>CSS</DropdownItem>
					<DropdownItem leftIcon={<AiOutlineThunderbolt />}>JavaScript</DropdownItem>
					<DropdownItem leftIcon={<AiOutlineThunderbolt />}>Awesome!</DropdownItem>
				</div>
			</CSSTransition>

			<CSSTransition
				in={activeMenu === 'assets'}
				timeout={500}
				classNames="menu-secondary"
				unmountOnExit
				onEnter={calcHeight}>
				<div className="menu">
					<DropdownItem goToMenu="main" leftIcon={<FaArrowLeft />}>
						<h2>Assets</h2>
					</DropdownItem>
					<DropdownItem leftIcon="🦘">Kangaroo</DropdownItem>
					<DropdownItem leftIcon="🐸">Frog</DropdownItem>
					<DropdownItem leftIcon="🦋">Horse?</DropdownItem>
					<DropdownItem leftIcon="🦔">Hedgehog</DropdownItem>
				</div>
			</CSSTransition>
		</div>
	);
}

export default Nav;
