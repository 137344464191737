import ApplicationLogo from '@/components/ApplicationLogo';
import ButtonNavLink from '@/components/ButtonNavLink';
import Dropdown from '@/components/Dropdown';
import NavLink from '@/components/NavLink';
import ResponsiveNavLink from '@/components/ResponsiveNavLink';
import { Html_Fuc_Ca } from '@/extension/Extension';
import { Link, usePage } from '@inertiajs/react';
import { useState } from 'react';
import Navbar from './Navbar';
import Footer from './Footer';
import Nav from './Nav';

export default function AuthenticatedLayout({ header, children }) {
    const [showingNavigationDropdown, setShowingNavigationDropdown] = useState(false);
    return (
        <>
         <Nav />
         <div className="min-h-screen">
           {children}
           <Footer />
       </div>
        </>
       
    );
}
