import React, { useState } from "react";
import { CSSTransition } from "react-transition-group";
import { FaBuilding, FaBriefcase, FaCertificate, FaUsers, FaUserGraduate, FaTasks, FaSuitcase, FaArrowLeft } from "react-icons/fa";
import { AiOutlineClose } from "react-icons/ai";
import { useForm } from "@inertiajs/react";

const CreateNewService = ({ show, onClose }) => {
    const [selectedOption, setSelectedOption] = useState(""); // Track selected option (organization/company)
    const [step, setStep] = useState(1); // Track current step (1 - select, 2 - features)
    const features = {
        organization: [
            { icon: <FaCertificate className="text-blue-500" />, text: "Create certificates for achievements" },
            { icon: <FaUserGraduate className="text-blue-500" />, text: "Manage student profiles" },
            { icon: <FaUsers className="text-blue-500" />, text: "Connect with other organizations" },
            { icon: <FaTasks className="text-blue-500" />, text: "Plan and track activities" },
            { icon: <FaBuilding className="text-blue-500" />, text: "Showcase your organization" },
        ],
        company: [
            { icon: <FaBriefcase className="text-green-500" />, text: "Post job opportunities" },
            { icon: <FaTasks className="text-green-500" />, text: "Manage company projects" },
            { icon: <FaUsers className="text-green-500" />, text: "Build a network of employees" },
            { icon: <FaSuitcase className="text-green-500" />, text: "Showcase your services" },
            { icon: <FaCertificate className="text-green-500" />, text: "Provide certifications for employees" },
        ],
    };

    const handleOptionChange = (option) => {
        setSelectedOption(option);
    };

    const handleBack = () => {
        setStep(1); // Go back to the first step
    };

    const handleContinue = () => {
        setStep(2); // Move to the second step where features are shown
    };

    const handleFinish = (e) => {
        window.location.href = route('app_web_frontend_profilecreate', { 'authority': selectedOption });
        onClose(); // Close the modal
    };

    return (
        <>
            <CSSTransition in={show} timeout={300} classNames="popup" unmountOnExit>
                <div className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white p-6 rounded-lg shadow-lg z-50 w-11/12 max-w-lg">
                    {/* Header */}
                    <div className="flex justify-between items-center border-b pb-2 mb-4">
                        <button
                            onClick={handleBack}
                            className={`text-gray-500 hover:text-blue-500 ${step !== 1 ? "" : "hidden"}`}
                        >
                            <FaArrowLeft size={24} />
                        </button>
                        <h2 className="text-2xl font-bold">Create</h2>
                        <button onClick={onClose} className="text-gray-500 hover:text-red-500">
                            <AiOutlineClose size={24} />
                        </button>
                    </div>

                    {/* Body */}
                    {step === 1 && (
                        <>
                            <p className="text-lg font-semibold mb-6">New Organization Profile or Company Profile</p>
                            <div className="flex flex-col gap-4">
                                {/* Option 1: Organization Profile */}
                                <div
                                    className={`flex items-center p-4 border rounded-lg cursor-pointer ${selectedOption === "organization"
                                            ? "border-blue-500 bg-blue-50"
                                            : "border-gray-200 hover:bg-gray-50"
                                        }`}
                                    onClick={() => handleOptionChange("organization")}
                                >
                                    <input
                                        type="radio"
                                        name="option"
                                        className="mr-4"
                                        checked={selectedOption === "organization"}
                                        onChange={() => handleOptionChange("organization")}
                                    />
                                    <div className="flex items-start">
                                        <FaBuilding size={32} className="text-blue-500 mr-4" />
                                        <div>
                                            <h3 className="text-lg font-semibold">Organization Profile</h3>
                                            <p className="text-gray-600 text-sm">
                                                Create a profile to represent your organization and connect with others.
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                {/* Option 2: Company Profile */}
                                <div
                                    className={`flex items-center p-4 border rounded-lg cursor-pointer ${selectedOption === "company"
                                            ? "border-blue-500 bg-blue-50"
                                            : "border-gray-200 hover:bg-gray-50"
                                        }`}
                                    onClick={() => handleOptionChange("company")}
                                >
                                    <input
                                        type="radio"
                                        name="option"
                                        className="mr-4"
                                        checked={selectedOption === "company"}
                                        onChange={() => handleOptionChange("company")}
                                    />
                                    <div className="flex items-start">
                                        <FaBriefcase size={32} className="text-green-500 mr-4" />
                                        <div>
                                            <h3 className="text-lg font-semibold">Company Profile</h3>
                                            <p className="text-gray-600 text-sm">
                                                Build a profile for your company to showcase your business and services.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    )}

                    {/* Step 2: Show Features of Selected Option */}
                    {step === 2 && selectedOption && (
                        <div className="mt-6">
                            <h4 className="text-xl font-semibold mb-4">
                                {selectedOption === "organization" ? "Features of Organization Profile" : "Features of Company Profile"}
                            </h4>
                            <ul className="space-y-3">
                                {features[selectedOption].map((feature, index) => (
                                    <li key={index} className="flex items-center gap-3">
                                        {feature.icon}
                                        <span className="text-gray-700">{feature.text}</span>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    )}

                    {/* Footer */}
                    <div className="flex justify-end mt-6 space-x-4">
                        <button
                            onClick={onClose}
                            className="py-2 px-4 text-sm text-gray-500 rounded-lg hover:bg-gray-100"
                        >
                            Cancel
                        </button>
                        <button
                            onClick={step === 1 ? handleContinue : handleFinish}
                            className={`py-2 px-6 text-sm rounded-lg ${selectedOption
                                    ? "bg-blue-500 text-white hover:bg-blue-600"
                                    : "bg-gray-300 text-gray-500 cursor-not-allowed"
                                }`}
                            disabled={!selectedOption && step === 1}
                        >
                            {step === 1 ? "Next" : "Get started"}
                        </button>
                    </div>
                </div>
            </CSSTransition>
            {/* Overlay */}
            {show && (
                <div
                    className="fixed inset-0 bg-black bg-opacity-50 z-40"
                    onClick={onClose}
                ></div>
            )}
        </>
    );
};

export default CreateNewService;
